/*
 * Copyright 2024 LLC Campus.
 */

export const AnalyticsEvents = {
  OPEN_PAGE: 'open_page',
  EMAIL_INPUT: 'email_input',
  ITEM_SELECTED: 'item_selected',
  PURCHASE_CLICK: 'purchase_click',
  PURCHASE_ERROR: 'purchase_error',
  ACTIVATION_CLICK: 'activation_click',
  RETURN_TO_STORE: 'return_to_store',
  RETURN_TO_APP: 'return_to_app',
  EMAIL_INPUTED: 'email_inputed',
  YOOKASSA_OPEN: 'yookassa_open',
  YOOKASSA_SUCCESS: 'yookassa_success',
  YOOKASSA_FAIL: 'yookassa_fail',
  YOOKASSA_CLOSED: 'yookassa_closed',
  CODE_COPIED: 'code_copied'
}

export const AnalyticsPresets = {
  PROMOCODE_CATALOG: { screen_name: 'promocodeCatalog', prefix: 'promocode_catalog_' },
  COURSE_CATALOG: { screen_name: 'courseCatalog', prefix: 'course_catalog_' },
}

/**
 * Класс для отправки аналитики.
 */
class Analytics {
  #google_app_name = 'campusPromocodeWebsite'

  /**
   * @typedef {Object} EventMetadata
   * @property {String} screen_name
   * @property {String} prefix
   */

  /**
   *
   * @param eventName {String}
   * @param metadata {EventMetadata}
   */
  async sendEvent (eventName, metadata) {
    await this.sendGoogleEvent((metadata.prefix || '') + eventName, metadata)
    await this.sendYandexEvent((metadata.prefix || '') + eventName, metadata)
  }

  /**
   * Отправляет ивент только один раз, пока не ресетнут localStorage.
   * @param eventName {String}
   * @param metadata {EventMetadata}
   */
  async sendEventOnce (eventName, metadata) {
    const key = 'analytics_' + (metadata.prefix || '') + eventName
    if (localStorage.getItem(key)) return
    localStorage.setItem(key, 'sent')
    await this.sendEvent(eventName, metadata)
  }

  /**
   * Записывает объект с метой в localStorage под своим ключом.
   * @param meta {EventMetadata}
   */
  setLocalStorageMeta (meta) {
    localStorage.setItem('analyticsMeta', JSON.stringify(meta))
  }

  /**
   * Читает объект с метой из localStorage под своим ключом.
   */
  getLocalStorageMeta () {
    return JSON.parse(localStorage.getItem('analyticsMeta'))
  }

  async sendGoogleEvent (eventName, metadata) {
    if (!metadata.screen_name) {
      console.error('Can\'t send Google event "' + eventName + '" without screen_name defined in metadata.')
      return
    }
    let googleOpts = { app_name: this.#google_app_name, ...metadata }
    if (googleOpts.prefix) delete googleOpts.prefix
    // eslint-disable-next-line no-undef
    await gtag('event', eventName, googleOpts)
  }

  async sendYandexEvent (eventName, metadata) {
    // eslint-disable-next-line no-undef
    await ym(100042033, 'reachGoal', eventName, metadata)
  }
}

const analytics = new Analytics()

export default analytics