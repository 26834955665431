/*
 * Copyright 2024 LLC Campus.
 */

import React, { useEffect, useState } from 'react'
import { fetchConfirmationToken, fetchCourseCatalog } from '../services/api'
import { toast } from 'react-toastify'
import { openYooKassaWidget } from '../yookassa'
import { useLocation } from 'react-router-dom'
import ItemGrid from '../components/ItemGrid'
import EmailInputContainer from '../components/EmailInputContainer'
import analytics, { AnalyticsEvents, AnalyticsPresets } from '../analytics'

function CourseCatalog () {
  const location = useLocation()
  const courseId = new URLSearchParams(location.search).get('courseId')
  const userId = new URLSearchParams(location.search).get('userId')

  const [tariffs, setCourseTariffs] = useState([])
  const [email, setEmail] = useState('')
  const [selectedTarifId, setSelectedTarifId] = useState(null)
  const [isWidgetOpen, setIsWidgetOpen] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [hasErrorToast, setHasErrorToast] = useState(false)

  useEffect(() => {
    let isMounted = true
    localStorage.clear()
    if (!courseId) {
      if (isMounted) {
        const errorMessage = 'Ошибка: courseId отсутствует. Страница не может быть загружена.'
        toast.error(errorMessage)
        setHasErrorToast(true)
      }
      return
    }

    const fetchData = async () => {
      try {
        const data = await fetchCourseCatalog(courseId)
        if (isMounted) {
          setCourseTariffs(data.items)
          analytics.sendEvent(AnalyticsEvents.OPEN_PAGE, {...AnalyticsPresets.COURSE_CATALOG})
        }
      } catch (error) {
        if (isMounted) {
          if (!hasErrorToast) {
            toast.error(error.message)
            setHasErrorToast(true)
          }
        }
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [courseId, hasErrorToast])

  const handleEmailChange = (event) => {
    analytics.sendEventOnce(AnalyticsEvents.EMAIL_INPUT, {...AnalyticsPresets.COURSE_CATALOG})
    setEmail(event.target.value)
    setIsEmailValid(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(event.target.value))

    if(isEmailValid) {
      analytics.sendEventOnce(AnalyticsEvents.EMAIL_INPUTED, {...AnalyticsPresets.COURSE_CATALOG})
    }
  }

  const handleCardClick = (tarifId) => {
    setSelectedTarifId(tarifId)
    analytics.sendEvent(AnalyticsEvents.ITEM_SELECTED, {...AnalyticsPresets.COURSE_CATALOG, promoCodeId:tarifId})
  }

  const handlePurchaseClick = async () => {
    const selectedTarif = tariffs.find(tarif => tarif._id === selectedTarifId)

    if (selectedTarif) {
      analytics.sendEvent(AnalyticsEvents.PURCHASE_CLICK, {...AnalyticsPresets.COURSE_CATALOG})
      try {
        const data = await fetchConfirmationToken(selectedTarifId, email, userId)

        if (data.confirmationToken && data.transactionId) {
          let returnPath = `/course?courseId=${courseId}`
          if(userId) returnPath += '&userId='+userId
          localStorage.setItem('purchaseData', JSON.stringify({
            returnPath: returnPath,
            activationLink: `campus://courses/activate?courseId=${courseId}&code=`
          }))
          analytics.setLocalStorageMeta(AnalyticsPresets.COURSE_CATALOG)
          setIsWidgetOpen(true)
          openYooKassaWidget(data.confirmationToken, selectedTarif, data.transactionId)
          analytics.sendEvent(AnalyticsEvents.YOOKASSA_OPEN, {...AnalyticsPresets.COURSE_CATALOG})
        }
      } catch (error) {
        toast.error(error.message)
        analytics.sendEvent(AnalyticsEvents.PURCHASE_ERROR, {...AnalyticsPresets.COURSE_CATALOG})
      }
    }
  }

  return (
    <div>
      <div id="yookassa-container" className={isWidgetOpen ? '' : 'hidden'}></div>
      <div className={`promocode-catalog ${isWidgetOpen ? 'hidden' : ''}`}>
        <div className="promocode-catalog-header">
          <div className="promocode-catalog-header-title">Покупка доступа к курсу</div>
        </div>
        <ItemGrid
          items={tariffs}
          selectedItemId={selectedTarifId}
          handleCardClick={handleCardClick}
        />
        <EmailInputContainer
          email={email}
          isEmailValid={isEmailValid}
          selectedItemId={selectedTarifId}
          handleEmailChange={handleEmailChange}
          handlePurchaseClick={handlePurchaseClick}
          infoBlock={
            <>
              <p>После оплаты:</p>
              <ul>
                <li>Код активации доступа к Курсу придет к вам на указанный e-mail.</li>
                <li>Вы можете его подарить другу или активировать подписку для себя в самом приложении: Настройки -
                  Кампус Premium.
                </li>
              </ul>
            </>
          }
          supportTextBlock={
            <>
              <p><strong>Возникли проблемы или есть вопросы?</strong></p>
              <p>Если у вас возникли проблемы с оплатой или есть какие-либо вопросы, пожалуйста, свяжитесь с нами через
                один из доступных каналов:</p>
              <ul>
                <li>E-mail: <a href="mailto:hello@campusapp.ru">hello@campusapp.ru</a></li>
                <li>Чат: в мобильном приложении</li>
              </ul>
              <p>Мы готовы помочь вам в любое время!</p>
            </>
          }
        />
      </div>
    </div>
  )
}

export default CourseCatalog
